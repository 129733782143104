/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-unused-vars */

import { UserAuthResponseType } from 'common/types/User.type';
import { createContext } from 'react';

export interface AuthContextProps {
  auth?: UserAuthResponseType;
  isLoggedIn: boolean;
  handleLogin: (user: UserAuthResponseType) => void;
  handleLogout: (error?: string) => void;
  loadingAuth: boolean;
  errorText: string;
}

const AuthContext = createContext<AuthContextProps>({
  auth: {},
  isLoggedIn: false,
  handleLogin: () => {},
  handleLogout: () => {},
  loadingAuth: false,
  errorText: '',
});

export default AuthContext;
