import {
  IconDefinition,
  faHome,
  faListAlt,
  faUserNinja,
  faUsers,
  faUserFriends,
  faGear,
  faClock,
  faBriefcase,
  faBusinessTime,
  faIdCard,
} from '@fortawesome/free-solid-svg-icons';

import routesConfig from './routesConfig';

export interface ChildrenNavigation {
  title: string;
  to: string;
  icon: IconDefinition;
  end?: boolean;
}

// Interface
export interface MainNavigationItem {
  title: string;
  to: string;
  icon: IconDefinition;
  //   roles: UserRole[];
  subNavItems?: ChildrenNavigation[];
  end?: boolean;
}

export const mainNavigationConfig: MainNavigationItem[] = [
  {
    title: 'Dashboard',
    to: routesConfig.dashboard.index.path,
    icon: faHome,
    end: true,
    // roles: [UserRole.ADMIN, UserRole.TEACHER],
  },
  {
    title: 'Users',
    to: routesConfig.users.list.path,
    icon: faUserFriends,
    // roles: [UserRole.ADMIN],
  },
  {
    title: 'Employees',
    to: routesConfig.employees.index.basePath!,
    icon: faUsers,
    // roles: [UserRole.ADMIN],
    subNavItems: [
      {
        title: 'Employees',
        to: `${routesConfig.employees.index.basePath}${routesConfig.employees.list.path}`,
        icon: faListAlt,
        // roles: [UserRole.ADMIN],
      },
      {
        title: 'Skills',
        to: `${routesConfig.employees.index.basePath}${routesConfig.employees.skills.path}`,
        icon: faUserNinja,
        // roles: [UserRole.ADMIN],
      },
    ],
  },
  {
    title: 'Clients',
    to: `${routesConfig.workingHours.clients.path}`,
    icon: faIdCard,
    // roles: [UserRole.ADMIN],
  },
  {
    title: 'Projects',
    to: `${routesConfig.workingHours.projects.path}`,
    icon: faBriefcase,
    // roles: [UserRole.ADMIN],
  },
  {
    title: 'Timesheet',
    to: `${routesConfig.workingHours.timesheet.path}`,
    icon: faClock,
    // roles: [UserRole.ADMIN],
  },
  {
    title: 'Reports',
    to: `${routesConfig.workingHours.reports.path}`,
    icon: faBusinessTime,
    // roles: [UserRole.ADMIN],
  },
  {
    title: 'Configuration',
    to: routesConfig.configuration.index.path,
    icon: faGear,
    // roles: [UserRole.ADMIN],
  },
];
