import { AbsoluteSpinner } from 'common/components/Spinners';
import routes from 'config/routesConfig';
import Clients from 'pages/WorkingHours/Clients';
import Projects from 'pages/WorkingHours/Projects';
import Reports from 'pages/WorkingHours/Reports';
import TimeSheet from 'pages/WorkingHours/TimeSheet';
import { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const WorkingHoursRoutes = () => {
  return (
    <Suspense fallback={<AbsoluteSpinner show />}>
      <Routes>
        <Route
          path={routes.workingHours.timesheet.path}
          element={<TimeSheet />}
        />
        <Route
          path={routes.workingHours.projects.path}
          element={<Projects />}
        />
        <Route path={routes.workingHours.clients.path} element={<Clients />} />
        <Route path={routes.workingHours.reports.path} element={<Reports />} />
        <Route
          path="*"
          element={
            <Navigate
              to={`${routes.workingHours.index.basePath}${routes.workingHours.timesheet.path}`}
              replace
            />
          }
        />
      </Routes>
    </Suspense>
  );
};

export default WorkingHoursRoutes;
