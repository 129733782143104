import { useCallback, useState } from 'react';

export const useLayoutHook = () => {
  const [loading, setLoading] = useState(false);

  const initLoading = useCallback(() => {
    setLoading(true);
  }, []);

  const finishLoading = useCallback(() => {
    setLoading(false);
  }, []);

  return {
    loading,
    initLoading,
    finishLoading,
  };
};
