const config = {
  apiUrl: process.env.REACT_APP_API_URL,
  env: process.env.NODE_ENV,
  basePublicUrl: process.env.REACT_APP_API_BASE_PUBLIC_URL,
};

const configuration = () => {
  if (!config.apiUrl) throw new Error('VITE_API_URL is missing!');
  if (!config.env) throw new Error('ENV is missing!');
  if (!config.basePublicUrl) throw new Error('Base Public Url is missing!');
  return config;
};

export default configuration;
