// Import types
import { UserRoleType } from 'common/types/User.type';

// Declare interfaces
export interface ConfigRoute {
  path: string;
  basePath?: string;
  roles: UserRoleType[];
}

export interface ConfigRoutes {
  [key: string]: {
    [key: string]: ConfigRoute;
  };
}

// Declare config
const routes: ConfigRoutes = {
  auth: {
    login: {
      path: '/auth/login',
      roles: [],
    },
    signUp: {
      path: '/auth/sign-up',
      roles: [],
    },
    resetPassword: {
      path: '/auth/reset-password',
      roles: [],
    },
  },
  users: {
    list: {
      path: '/users',
      roles: [],
    },
    user: {
      path: '/user/:id',
      roles: [],
    },
  },
  configuration: {
    index: {
      path: '/configuration',
      basePath: '/employees',
      roles: [],
    },
  },
  employees: {
    index: {
      path: '/employees/*',
      basePath: '/employees',
      roles: [],
    },
    list: {
      path: '/list',
      roles: [],
    },
    listEmployee: {
      path: '/employee/:employeeId',
      roles: [],
    },
    employeeCv: {
      path: '/employee/:employeeId/cv/:employeeCvId',
      roles: [],
    },
    skills: {
      path: '/skills',
      roles: [],
    },
  },
  dashboard: {
    index: {
      path: '/',
      roles: [],
    },
  },
  workingHours: {
    index: {
      path: '/*',
      basePath: '/',
      roles: [],
    },
    timesheet: {
      path: '/timesheet',
      roles: [],
    },
    projects: {
      path: '/projects',
      roles: [],
    },
    clients: {
      path: '/clients',
      roles: [],
    },
    reports: {
      path: '/reports',
      roles: [],
    },
  },
};

export default routes;
