import { lazy, Suspense } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// Utils
import configuration from 'config';

// Toastify
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import Context
import { LayoutContext } from 'context/LayoutContext';
import AuthContext from 'context/AuthContext';

// Import routes
import routes from 'config/routesConfig';
import EmployeeRoutes from 'routes/EmployeeRoutes';
import WorkingHoursRoutes from 'routes/WorkingHoursRoutes';

// Import Layout
import AuthLayout from 'layouts/AuthLayout';
import MainLayout from 'layouts/MainLayout';

import { useLayoutHook } from 'context/useLayoutHook';
import useAuthHook from 'context/useAuthHook';

// Import services
import { configureAPI } from 'utils/api';

// Import Components
import AbsoluteSpinner from 'common/components/Spinners/AbsoluteSpinner';

// Import Pages
const Login = lazy(() => import('pages/Login/LoginPage'));
const UsersPage = lazy(() => import('pages/User/UsersPage'));
const DashboardPage = lazy(() => import('pages/DashboardPage'));
const EmployeePage = lazy(() => import('pages/Employees/EmployeePage'));
const PageNotFound = lazy(() => import('pages/PageNotFound'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const ConfigurationPage = lazy(() => import('pages/ConfigurationPage'));
// Declarations
const queryClient = new QueryClient();

const App = () => {
  const navigate = useNavigate();
  const layout = useLayoutHook();
  const authHookProps = useAuthHook();
  const { isLoggedIn, loadingAuth, handleLogout, auth } = authHookProps;

  if (loadingAuth) {
    return <AbsoluteSpinner show />;
  }

  // Handlers
  configureAPI({
    token: auth?.accessToken,
    onSignOut: handleLogout,
    isNotFound: () => navigate('/404page'),
  });

  const renderDevTools =
    configuration().env === 'production' ? null : (
      <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
    );

  return (
    <QueryClientProvider client={queryClient}>
      <LayoutContext.Provider value={layout}>
        <AuthContext.Provider value={authHookProps}>
          {renderDevTools}
          <ToastContainer
            position="top-right"
            hideProgressBar={false}
            newestOnTop={true}
            closeOnClick
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          {!isLoggedIn ? (
            <AuthLayout>
              <Suspense fallback={<AbsoluteSpinner show />}>
                <Routes>
                  <Route path={routes.auth.login.path} element={<Login />} />
                  <Route
                    path={routes.auth.resetPassword.path}
                    element={<ResetPassword />}
                  />
                  <Route path="*" element={<Login />} />
                </Routes>
              </Suspense>
            </AuthLayout>
          ) : null}
          {isLoggedIn ? (
            <MainLayout>
              <Suspense fallback={<AbsoluteSpinner show />}>
                <Routes>
                  <Route
                    path={routes.users.list.path}
                    element={<UsersPage />}
                  />
                  <Route
                    path={routes.dashboard.index.path}
                    element={<DashboardPage />}
                  />
                  <Route
                    path={routes.configuration.index.path}
                    element={<ConfigurationPage />}
                  />
                  <Route
                    path={routes.employees.index.path}
                    element={<EmployeeRoutes />}
                  />

                  {[
                    routes.employees.listEmployee.path,
                    routes.employees.employeeCv.path,
                  ].map((path, index) => (
                    <Route key={index} path={path} element={<EmployeePage />} />
                  ))}

                  <Route
                    path={routes.workingHours.index.path}
                    element={<WorkingHoursRoutes />}
                  />
                  <Route path="/404page" element={<PageNotFound />} />
                  <Route
                    path="*"
                    element={<Navigate to="/404page" replace />}
                  />
                </Routes>
              </Suspense>
            </MainLayout>
          ) : null}
        </AuthContext.Provider>
      </LayoutContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
