import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';

// Import Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import NavItem from 'common/components/Navigation/NavItem';
import logoUrl from 'assets/image/logo.png';

// Import context
import AuthContext from 'context/AuthContext';

// Import config
import { mainNavigationConfig } from 'config/navigationConfig';

const Navgiation: FC = () => {
  const { handleLogout, auth } = useContext(AuthContext);

  return (
    <div className="bottom-0 z-50 flex flex-col items-start justify-between h-full min-h-screen transition duration-500 ease-in-out bg-gray-800">
      <div>
        <div className="items-center justify-start hidden p-6 space-x-3 xl:flex">
          <Link to="/" className="flex items-center text-white">
            <img
              className="w-[180px] h-[40px]"
              src={logoUrl}
              alt="Homesourcing App"
            />
          </Link>
        </div>

        <div className="flex flex-col items-start justify-start w-full gap-3 pb-5 pl-6 mt-6 ">
          {mainNavigationConfig.map(({ title, to, icon, subNavItems, end }) => (
            <NavItem
              key={to}
              title={title}
              to={to}
              icon={icon}
              subNavItems={subNavItems}
              end={end}
            />
          ))}
        </div>
      </div>
      {/* Footer */}
      <div className="flex flex-col items-center justify-between w-64 h-auto px-6 py-4 space-y-32 border-t border-gray-600 ">
        <div className="flex items-center justify-between w-full ">
          <div className="flex items-center justify-center space-x-2">
            {/* <div>
              <img 
                className="rounded-full"
                src="https://i.ibb.co/L1LQtBm/Ellipse-1.png"
                alt="avatar"
              />
            </div> */}
            <div className="flex flex-col items-start justify-start">
              <p className="text-sm leading-5 text-white cursor-pointer">
                {/* {role} */}
              </p>
              <p className="text-xs leading-3 text-white cursor-pointer">
                {auth?.user?.email}
              </p>
            </div>
          </div>

          <span onClick={() => handleLogout()} className="cursor-pointer">
            <FontAwesomeIcon icon={faSignOutAlt} color="white" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Navgiation;
