import React, { ReactElement, ReactNode } from 'react';

interface AuthLayoutProps {
  children: ReactNode | string;
}

const AuthLayout = ({ children }: AuthLayoutProps): ReactElement => {
  return (
    <div>
      <div>{children}</div>
    </div>
  );
};

export default AuthLayout;
