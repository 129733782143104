/* eslint-disable no-shadow */
import { useEffect, useState } from 'react';

// Import Interface
import { UserAuthResponseType } from 'common/types/User.type';

const useAuthHook = () => {
  const [auth, setAuth] = useState<UserAuthResponseType>();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loadingAuth, setLoadingAuth] = useState(true);
  const [errorText, setError] = useState('');

  const handleAuth = () => {
    setLoadingAuth(true);
    const auth = JSON.parse(localStorage.getItem('auth') || '{}');
    if (auth?.accessToken) {
      setAuth(auth);
      setIsLoggedIn(true);
      setError('');
    }
    setLoadingAuth(false);
  };

  const handleLogin = (auth: UserAuthResponseType) => {
    setAuth(auth);
    setIsLoggedIn(true);
    localStorage.setItem('auth', JSON.stringify(auth));
  };

  const handleLogout = (error = '') => {
    setError(error);
    setAuth(undefined);
    setIsLoggedIn(false);
    localStorage.clear();
  };

  useEffect(() => {
    handleAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    auth,
    isLoggedIn,
    handleLogin,
    handleLogout,
    loadingAuth,
    errorText,
  };
};

export default useAuthHook;
