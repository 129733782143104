import { FC, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';

// Layout Hook
import { useLayoutHook } from 'context/useLayoutHook';

// Import Container
import Navigation from 'containers/Navigation';

// Import Components
import { AbsoluteSpinner } from 'common/components/Spinners';

interface AuthLayoutProps {
  children: ReactElement;
}

const MainLayout: FC<AuthLayoutProps> = ({ children }: AuthLayoutProps) => {
  const location = useLocation();

  const layout = useLayoutHook();
  const { loading } = layout;

  return (
    <div className="flex flex-row">
      <Navigation />
      <div
        className={`bg-[#f3f4f6] relative max-h-screen z-50 ${
          location.pathname !== '/404page' && 'p-5'
        } w-full overflow-y-auto`}
      >
        <div className="bg-gradient-to-b from-blue-darkgradient to-blue-lightgradient absolute -top-1 left-0 w-full h-[35vh]" />
        <div className="relative h-full">
          <AbsoluteSpinner show={loading} />
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
