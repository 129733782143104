import { lazy, Suspense } from 'react';

import { Routes, Route, Navigate } from 'react-router-dom';

// Import routes
import routes from 'config/routesConfig';

// Import components
import AbsoluteSpinner from 'common/components/Spinners/AbsoluteSpinner';

// Import pages
const SkillsPage = lazy(() => import('pages/Employees/Skills/SkillsPage'));
const EmployeesPage = lazy(() => import('pages/Employees/EmployeesPage'));

const EmployeeRoutes = () => {
  return (
    <Suspense fallback={<AbsoluteSpinner show />}>
      <Routes>
        <Route path={routes.employees.list.path} element={<EmployeesPage />} />
        <Route path={routes.employees.skills.path} element={<SkillsPage />} />
        <Route
          path="*"
          element={
            <Navigate
              to={`${routes.employees.index.basePath}${routes.employees.list.path}`}
              replace
            />
          }
        />
      </Routes>
    </Suspense>
  );
};

export default EmployeeRoutes;
